import * as React from 'react'
import ProfilePic from '../images/profile_placeholder.png';

const items = require('./translations.json')
const axios = require('axios').default

export const getTranslation = (key, defaultText, html = true) => {
  let value = Object.keys(items).indexOf(key) > -1 ? items[key] : defaultText

  if (Object.keys(items).indexOf(key) === -1) {
    axios.post('https://backend.buildup.am/translations', {
      key: key,
      value: defaultText,
    }).then(()=>{

    }).catch(()=>{

    })
  }

  if (!html) {
    return value
  }
  const highlighted = replaceHighlightTag(value);
  return <span dangerouslySetInnerHTML={{ __html: highlighted }} />
}

export const replaceHighlightTag = (html) => {
  if(!html){
    return html;
  }
  if(typeof html !== 'string'){
    return html;
  }
  html =  html.replaceAll('<highlight>','<span class="highlight">');
  return html.replaceAll('</highlight>','</span>');
}


export const getOptions = field => {
  let key = field + '_options'
  let values =
    Object.keys(items).indexOf(key) > -1 && items[key]
      ? items[key].split(',')
      : []
  if (Object.keys(items).indexOf(key) === -1) {
    axios
      .post('https://backend.buildup.am/translations', { key: key, value: key })
      .then(() => {})
      .catch(() => {})
  }
  return values.map(val => {
    return { label: val.trim(), value: val.trim() }
  })
}

export const DEFAULT_PROFILE_IMAGE = `profilepic.png`
export const DEFAULT_PROFILE_IMAGE_URL = ProfilePic;
